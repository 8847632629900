import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '../components/search/App';
function isSearchEnabled() {
    const params = new URLSearchParams(window.location.search);
    return params.has('search');
}
if (isSearchEnabled()) {
    const container = document.getElementById('search-root');
    if (container) {
        const root = createRoot(container);
        root.render(React.createElement(App, null));
    }
}
