import React from 'react';
import { liteClient } from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Highlight } from 'react-instantsearch';
function Hit({ hit }) {
    return (React.createElement("div", null,
        React.createElement("h1", null,
            React.createElement(Highlight, { attribute: "name", hit: hit })),
        React.createElement("p", null,
            React.createElement(Highlight, { attribute: "description", hit: hit }))));
}
export function App() {
    const appId = process.env.ALGOLIA_APPLICATION_ID || '';
    const searchKey = process.env.ALGOLIA_API_KEY || '';
    const searchClient = liteClient(appId, searchKey);
    return (React.createElement(InstantSearch, { searchClient: searchClient, indexName: "public_idx", future: {
            preserveSharedStateOnUnmount: true,
        } },
        React.createElement(SearchBox, null),
        React.createElement(Hits, { hitComponent: Hit })));
}
